<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import router from "@/router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { resetPassword } from "../../services/auth";
export default {
  beforeRouteEnter(to, from, next) {
    if (!to.query.code) {
      next("/");
    }
    next();
  },
  setup() {
    const { currentRoute } = useRouter();
    const store = useStore();
    const toast = useToast();
    const user = ref({
      email: "",
      password: "",
      confirm_password: "",
      code: currentRoute.value.query.code,
    });

    const handleSubmit = async () => {
      try {
        const { data } = await resetPassword(user.value);
        store.dispatch("user/setUser", data.user);
        router.push({ name: "updates" });
      } catch (error) {
        toast.error(
          Array.isArray(error.response.data.message)
            ? error.response.data.message[0].messages[0].message
            : error.response.data.message
        );
      }
    };
    return { user, handleSubmit };
  },
};
</script>

<template>
  <div class="min-h-screen bg-white flex">
    <div
      class="
        flex-1 flex flex-col
        justify-center
        py-12
        px-4
        sm:px-6
        lg:flex-none lg:px-20
        xl:px-24
      "
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img
            class="w-auto"
            src="@/assets/logomark-trans.png"
            alt="Workflow"
          />
          <h2 class="mt-6 text-3xl font-extrabold text-light-blue-900">
            Create account
          </h2>
          <p class="mt-2 text-sm text-gray-500 font-medium">
            Setup password for your account
          </p>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form @submit.prevent="handleSubmit" class="space-y-6">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    v-model="user.email"
                    autocomplete="email"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    v-model="user.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label
                  for="confirm_password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Repeat Password
                </label>
                <div class="mt-1">
                  <input
                    v-model="user.confirm_password"
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-start">
                  <input
                    id="remember_me"
                    name="remember_me"
                    type="checkbox"
                    class="
                      h-4
                      w-4
                      text-blue-600
                      focus:ring-blue-500
                      border-gray-300
                      rounded
                    "
                  />
                  <label
                    for="remember_me"
                    class="ml-2 flex text-sm text-gray-900 flex-col"
                  >
                    <span class="block text-blue-600 font-semibold"
                      >Terms & Conditions</span
                    >
                    <span class="block text-gray-500"
                      >By creating an account, you agreed to accept our Terms &
                      Conditions. Please read carefully.
                    </span>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                class="
                  w-full
                  flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-sm
                  font-medium
                  text-white
                  bg-light-blue-700
                  hover:bg-light-blue-800
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-light-blue-500
                "
              >
                Create
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="@/assets/img/form-sign.jpg"
        alt=""
      />
    </div>
  </div>
</template>